<template>
  <div>
    <header style="position: relative;">
      <div style=" margin: 0 auto;">
        <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/bossfront/Group 2176.png" />
      </div>
      <div style="position: absolute;top: 200px;left:300px;">
        <div>
          <h1 style="font-size: 50px;"><b>服务中心</b></h1>
          <h5 style="font-size: 20px;">服务为本，客户至上，快速响应</h5>
        </div>
      </div>
      <div style="position: absolute;bottom: -110px;text-align: center;width: 100%; size: medium;">
        <div class="boss-content" style="height: 230px; display: flex;justify-content: center;align-items: center;">
          <div class="boss-content" style="box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);background-color: white;display: flex;justify-content: center;align-items: center;">
            <div class="tabhome" :class="{ active: tabPosition === 'frontserve' }"
              @click="router('serve/frontserve', 'frontserve')"><b>售前咨询</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'afterserve' }"
              @click="router('serve/afterserve', 'afterserve')"><b>售后服务</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'serve' }" @click="router('serve/serve', 'serve')">
              <b>客户成功服务</b></div>
            <div class="tabhome" :class="{ active: tabPosition === 'complain' }"
              @click="router('serve/complain', 'complain')"><b>投诉反馈</b></div>
          </div>
        </div>
      </div>
    </header>
    <main class="main">
      <div class="counseling">
        <Counseling></Counseling>
      </div>

      <div class="boss-content" style="margin-top: 30px;">
        <div class="title" style="height: 200px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>售后支持</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
        <div style=" margin: 0 auto;height: 100px;">
          <p style="text-align: center">
            壹公里直秉承“服务为本，客户至上，快速响应”的服务理念，为此，我们建立了强大的售后和技术支持体系。
            壹公里采取自上而下的售后质量服务体系，遵循严格标准的解决问题机制，加上完善的服务监督制度，确保您的问题得到及时且满意的解决。
            构建制造业企业的一体化整合管理平台。
          </p>
        </div>
        <div style=" margin: 0 auto">
          <el-row :gutter="40">
            <el-col :xs="12" :md="12">
              <div class="grid-content bg-purple">
                <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 170px;
                  padding: 20px 0px 0px 20px;
                ">
                  <el-col :xs="4" :md="5">
                    <img src="../assets/images/bossafter/Group 2153.png" />
                  </el-col>
                  <el-col :xs="12" :md="14">
                    <h3>产品文档</h3>
                    <h4 style="color: #00f">文档中心&nbsp;&nbsp; <b>→</b></h4>
                    <p>提供从新手到专业开发者的所有资源，新手入门与最佳实践</p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :xs="12" :md="12">
              <div class="grid-content bg-purple">
                <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 170px;
                  padding: 20px 0px 0px 20px;
                ">
                  <el-col :xs="4" :md="5">
                    <img src="../assets/images/bossafter/Group 2150.png" />
                  </el-col>
                  <el-col :xs="12" :md="14">
                    <h3>售后在线</h3>
                    <h4 style="color: #00f">立即沟通&nbsp;&nbsp; <b>→</b></h4>
                    <p>专业的售后交付工程师在线，助您快速解决的问题</p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :xs="12" :md="12">
              <div class="grid-content bg-purple">
                <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 170px;
                  padding: 20px 0px 0px 20px;
                ">
                  <el-col :xs="4" :md="5">
                    <img src="../assets/images/bossafter/Group 1952.png" />
                  </el-col>
                  <el-col :xs="12" :md="14">
                    <h3>售后服务热线</h3>
                    <h4 style="color: #00f">0757-83785031</h4>
                    <p>专业实施工程师提供精准、高效的售后服务支持</p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :xs="12" :md="12">
              <div class="grid-content bg-purple">
                <el-row style="
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 170px;
                  padding: 20px 0px 0px 20px;
                ">
                  <el-col :xs="4" :md="5">
                    <img src="../assets/images/bossafter/Group 2153(1).png" />
                  </el-col>
                  <el-col :xs="12" :md="14">
                    <h3>提交工单</h3>
                    <h4 style="color: #00f">工单系统&nbsp;&nbsp; <b>→</b></h4>
                    <p>提交BUG反馈、二次开发等问题，坐席工程师5*8小时为您服务</p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="neirong">
        <div class="boss-content">
          <div class="title" style="height: 200px;position: relative;">
            <div style="position: relative;">
              <h1 style="font-size: 40px;"><b>服务内容</b></h1>
              <div style="position: absolute;top: -15px;left: -20px; ">
                <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
              </div>
            </div>
          </div>
          <div style=" margin: 0 auto;height:80px">
            <p style="text-align: center">
              为保证软件版本的及时更新、软件系统持续有效运行，减少系统的运行成本和相关风险，
              且在发生技术中断时提供可靠响应，壹公里提供了提供多种服务内容，以满足不同用户的需求。
            </p>
          </div>
          <div style="margin: 0 auto;text-align: center;">
            <el-row :gutter="10" style="margin-bottom: 10px;">
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>版本升级</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>需求反馈</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>问题解析</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>产品培训</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>信息巡检</h5>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>专属服务</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>定制化培训</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>现场技术支持</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>紧急救援服务</h5>
                </div>
              </el-col>
              <el-col :xs="5" :md="5" class="mycol">
                <div class="grid-content bg-purple" style="display: flex;align-items: center;justify-content: center;">
                  <h5>协助二次开发</h5>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Counseling from "../views/Counseling.vue";
export default {
  components: {
    Counseling,
  },
  data() {
    return {
      tabPosition: 'afterserve'
    };
  },
  computed: {},
  watch: {},
  methods: {
    router(val, tab) {
      this.$router.push("/" + val + "/");
      this.tabPosition = tab
    }
  },
  created() { },
  mounted() {
    this.getQualification1();
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.tabhome {
  margin-top: 0px;
  background-color: white;
  height: 76px;
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.tabhome.active {
  margin-top: 0px;
  background: url('../assets/images/bossfront/Group 2148.png') no-repeat;
  background-size: 100%;
}

.mycol {
  width: 20%;
  height: 50px;
  line-height: 50px;
}

.bg-purple {
  background: white;
}

.grid-content {
  min-height: 50px;
}

img {
  height: 70px;
}

.el-row {
  margin-bottom: 20px;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background: url("../assets/images/bossfront/Group 2176.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  position: relative;
  z-index: 1;
}

.neirong {
  background: url("../assets/images/jianjie/Rectangle 4559.png");
  background-size: 100%;
  height: 500px;
}

.counseling {
  z-index: 10;
  right: 28px;
  position: relative;
  position: fixed;
  width: 56px;
  height: 344px;
  bottom: 40px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 8px 0px rgba(44, 81, 154, 0.16);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #DCE0E4;
}</style>